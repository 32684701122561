'use client';

import { FC, useRef } from 'react';
import cn from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import styles from './hero.module.scss';

gsap.registerPlugin(ScrollTrigger);

export const Hero: FC = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const imageLimeRef = useRef<HTMLImageElement>(null);
  const imagesWrapRef = useRef<HTMLDivElement>(null);
  const topTextRef = useRef<HTMLParagraphElement>(null);
  const bottomTextRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const imageWrapTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: wrapRef.current,
      });
      const topTextTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: wrapRef.current,
      });
      const bottomTextTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: wrapRef.current,
      });
      const imagesTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: wrapRef.current,
      });

      imageWrapTL.to(imageLimeRef.current, {
        opacity: '1',
        duration: 0.5,
      });
      imagesTL.to(imagesWrapRef.current, {
        opacity: '1',
        duration: 0.5,
      });

      topTextTL.to(topTextRef.current, {
        opacity: '1',
        duration: 0.5,
        delay: 0.5,
      });

      bottomTextTL.to(bottomTextRef.current, {
        opacity: '1',
        duration: 0.5,
        delay: 1,
      });
    },
    { scope: wrapRef }
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.inner} ref={wrapRef}>
        <div className={styles.contentTop}>
          <picture>
            <source srcSet="/images/main/hero/lime-text.webp" />
            <img
              alt="lime"
              className={styles.limeText}
              src="/images/main/hero/lime-text.png"
              ref={imageLimeRef}
            />
          </picture>
          <p className={cn(styles.text, styles.textTop)} ref={topTextRef}>
            LIME - экспресс в экологичную и инновационную реальность!
          </p>
        </div>
        <div className={styles.contentBottom} ref={bottomTextRef}>
          <p className={cn(styles.text, styles.textBottom)}>
            Мы строим устойчивое будущее на принципах лидерства, развития технологических продуктов, безопасности и бережном отношении к окружающей среде!
          </p>
          <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.arrowIcon}
          >
            <g filter="url(#filter0_b_636_3660)">
              <circle
                cx="36"
                cy="36"
                r="36"
                transform="rotate(90 36 36)"
                fill="white"
                fillOpacity="0.1"
              />
            </g>
            <path
              d="M28.5 29L43 43.5M43 43.5C41 40.8333 39 33.5 42 30.5M43 43.5C40.6667 41.5 34.8 38.7 30 43.5"
              stroke="white"
              strokeLinecap="round"
            />
            <defs>
              <filter
                id="filter0_b_636_3660"
                x="-15"
                y="-15"
                width="102"
                height="102"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_636_3660"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_636_3660"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.imagesBlock} ref={imagesWrapRef}>
          <div className={styles.imagesInner}>
            <div className={styles.limeBlock}>
              <picture>
                <source srcSet="/images/main/hero/lime.webp" />
                <img
                  alt="lime"
                  className={styles.lime}
                  src="/images/main/hero/lime.png"
                />
              </picture>
              <svg
                width="616"
                height="542"
                viewBox="0 0 616 542"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.shadow}
              >
                <g filter="url(#filter0_f_636_3655)">
                  <circle cx="308" cy="234" r="158" fill="black" />
                </g>
                <defs>
                  <filter
                    id="filter0_f_636_3655"
                    x="0"
                    y="-74"
                    width="616"
                    height="616"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="75"
                      result="effect1_foregroundBlur_636_3655"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <picture>
              <source srcSet="/images/main/hero/platfom-new.png" />
              <img
                alt="platform"
                className={styles.platform}
                src="/images/main/hero/platfom-new.png"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
};
