'use client';

import { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './menu.module.scss';
import { Button, StyledLink } from '../../../ui-kit';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleOpenProvider: VoidFunction;
}

export const Menu: FC<Props> = ({ isOpen, handleClose, handleOpenProvider }) => {
  const { account } = useWeb3React();
  const wrapRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapRef, handleClose);

  return (
    <div className={cn(styles.overlay, { [styles.overlayActive]: isOpen })}>
      <div className={styles.wrap} ref={wrapRef}>
        <nav className={styles.nav}>
          <Link to="#about" className={styles.navLink}>
            О нас
          </Link>
          <Link to="#projects" className={styles.navLink}>
            Проекты
          </Link>
          <a
            href="https://blog.lime.fun/"
            className={styles.navLink}
            target="_blank"
          >
            Блог
          </a>
          <a
            href="https://lime-fun.gitbook.io/white-paper"
            className={styles.navLink}
            target="_blank"
          >
            White Paper
          </a>
        </nav>
        {account ? (
            <StyledLink href="/profile" className={styles.connectBtn}>
              Кабинет инвестора
            </StyledLink>
        ) : (
            <Button className={styles.connectBtn} onClick={handleOpenProvider}>Подключить кошелек</Button>
        )}
      </div>
    </div>
  );
};
