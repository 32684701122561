'use client';

import { FC, useEffect, useRef, useState } from 'react';
import styles from './deposit.module.scss';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';
import { LandingInfo } from '../../../api/types';
import { getLandingInfo } from '../../../api';
import { useGraphicData } from '../../profile/subpages/cabinet/subcomponents/graphic/graphic.hook';

gsap.registerPlugin(ScrollTrigger);

export const Deposit: FC = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const titleTopRef = useRef<HTMLSpanElement>(null);
  const titleBottomRef = useRef<HTMLSpanElement>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const numbersRef = useRef<HTMLDivElement>(null);
  const [landingInfo, setLandingInfo] = useState<LandingInfo | null>(null);
  const { currentPrice } = useGraphicData();

  useEffect(() => {
    const init = async () => {
      const landingInfo = await getLandingInfo();
      setLandingInfo({ ...landingInfo, percent: landingInfo.percent - 100 });
    };

    init().catch(console.error);
  }, []);

  useGSAP(() => {
    const titleTopTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const titleBottomTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const graphTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const numbersTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });

    const spark1TL = gsap.timeline({ repeat: -1, reversed: true });
    const spark2TL = gsap.timeline({ repeat: -1, reversed: true });

    titleTopTL.to(titleTopRef.current, {
      opacity: '1',
      duration: 0.5,
    });

    titleBottomTL.to(titleBottomRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 0.3,
    });

    graphTL.to(graphRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 0.6,
    });

    numbersTL.to(numbersRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 0.9,
    });
  });

  return (
    <div className={styles.wrap}>
      <div className={styles.inner} ref={wrapRef}>
        <h2 className={styles.title}>
          <span className={styles.titleTop} ref={titleTopRef}>
            Сделайте вклад в новый
          </span>
          <span className={styles.titleBottom} ref={titleBottomRef}>
            <span className={styles.titleAccent}>экологичный </span>
            <span className={styles.titleBottomText}>формат жизни!</span>
          </span>
        </h2>
        <div ref={graphRef} className={styles.graphWrap}>
          <svg
            width="1440"
            height="700"
            viewBox="0 0 1440 700"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.graph}
          >
            <line
              x1="195.1"
              y1="9"
              x2="195.1"
              y2="700"
              stroke="url(#paint0_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="745.1"
              y1="9"
              x2="745.1"
              y2="700"
              stroke="url(#paint1_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="245.1"
              y1="9"
              x2="245.1"
              y2="700"
              stroke="url(#paint2_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="795.1"
              y1="9"
              x2="795.1"
              y2="700"
              stroke="url(#paint3_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="295.1"
              y1="9"
              x2="295.1"
              y2="700"
              stroke="url(#paint4_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="845.1"
              y1="9"
              x2="845.1"
              y2="700"
              stroke="url(#paint5_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="345.1"
              y1="9"
              x2="345.1"
              y2="700"
              stroke="url(#paint6_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="895.1"
              y1="9"
              x2="895.1"
              y2="700"
              stroke="url(#paint7_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="395.1"
              y1="9"
              x2="395.1"
              y2="700"
              stroke="url(#paint8_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="945.1"
              y1="9"
              x2="945.1"
              y2="700"
              stroke="url(#paint9_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="445.1"
              y1="9"
              x2="445.1"
              y2="700"
              stroke="url(#paint10_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="995.1"
              y1="9"
              x2="995.1"
              y2="700"
              stroke="url(#paint11_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="495.1"
              y1="9"
              x2="495.1"
              y2="700"
              stroke="url(#paint12_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="1045.1"
              y1="9"
              x2="1045.1"
              y2="700"
              stroke="url(#paint13_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="545.1"
              y1="9"
              x2="545.1"
              y2="700"
              stroke="url(#paint14_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="1095.1"
              y1="9"
              x2="1095.1"
              y2="700"
              stroke="url(#paint15_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="595.1"
              y1="9"
              x2="595.1"
              y2="700"
              stroke="url(#paint16_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="1145.1"
              y1="9"
              x2="1145.1"
              y2="700"
              stroke="url(#paint17_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="645.1"
              y1="9"
              x2="645.1"
              y2="700"
              stroke="url(#paint18_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="1195.1"
              y1="9"
              x2="1195.1"
              y2="700"
              stroke="url(#paint19_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="695.1"
              y1="9"
              x2="695.1"
              y2="700"
              stroke="url(#paint20_linear_636_3574)"
              strokeWidth="0.2"
            />
            <line
              x1="1245.1"
              y1="9"
              x2="1245.1"
              y2="700"
              stroke="url(#paint21_linear_636_3574)"
              strokeWidth="0.2"
            />
            <path
              d="M182 328.713C183.518 326.713 188.195 323.912 194.755 328.713C201.315 333.515 239.398 391.878 257.619 420.46C272.196 443.611 311.737 481.853 353.282 449.613C405.213 409.313 420.701 345.005 467.166 346.72C513.63 348.435 509.986 373.3 550.984 369.871C583.783 367.127 607.167 350.435 614.759 342.433C626.907 328.999 652.962 297 660.313 275.552C673.356 237.5 700.4 202.669 750.509 226.678C788 244.64 814 270 828.861 248.114C850.339 216.484 876.237 135.789 905.391 122.069C934.546 108.35 943.664 123.784 954.597 130.644C965.53 137.503 981.01 139.218 1022.92 110.065C1064.83 80.9122 1193.29 12.3167 1227 14.0316"
              stroke="url(#paint22_linear_636_3574)"
              strokeOpacity="0.8"
              strokeWidth="5"
            />
            <g style={{ mixBlendMode: 'color-dodge' }}>
              <circle
                cx="918"
                cy="118"
                r="16"
                fill="url(#paint23_diamond_636_3574)"
              />
            </g>
            <g style={{ mixBlendMode: 'color-dodge' }}>
              <circle
                cx="918"
                cy="118"
                r="32"
                fill="url(#paint24_diamond_636_3574)"
                fillOpacity="0.5"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M884.395 41C879.206 41 875 45.2065 875 50.3954V70.2999C875 75.4889 879.206 79.6953 884.395 79.6953H903.751C904.429 79.6953 905.061 80.0393 905.43 80.6089L914.787 95.0703C915.575 96.2884 917.357 96.2884 918.145 95.0705L927.504 80.6087C927.872 80.0392 928.504 79.6953 929.183 79.6953H948.605C953.794 79.6953 958 75.4889 958 70.2999V50.3954C958 45.2065 953.794 41 948.605 41H884.395Z"
              fill="white"
              fillOpacity="0.1"
            />
            <text x={890} y={65} style={{ fill: 'white' }}>
              {currentPrice.toFixed(4)}
            </text>
            <defs>
              <linearGradient
                id="paint0_linear_636_3574"
                x1="195"
                y1="700.001"
                x2="195"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_636_3574"
                x1="745"
                y1="700.001"
                x2="745"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_636_3574"
                x1="245"
                y1="700.001"
                x2="245"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_636_3574"
                x1="795"
                y1="700.001"
                x2="795"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_636_3574"
                x1="295"
                y1="700.001"
                x2="295"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_636_3574"
                x1="845"
                y1="700.001"
                x2="845"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_636_3574"
                x1="345"
                y1="700.001"
                x2="345"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_636_3574"
                x1="895"
                y1="700.001"
                x2="895"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_636_3574"
                x1="395"
                y1="700.001"
                x2="395"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_636_3574"
                x1="945"
                y1="700.001"
                x2="945"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_636_3574"
                x1="445"
                y1="700.001"
                x2="445"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_636_3574"
                x1="995"
                y1="700.001"
                x2="995"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint12_linear_636_3574"
                x1="495"
                y1="700.001"
                x2="495"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint13_linear_636_3574"
                x1="1045"
                y1="700.001"
                x2="1045"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint14_linear_636_3574"
                x1="545"
                y1="700.001"
                x2="545"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint15_linear_636_3574"
                x1="1095"
                y1="700.001"
                x2="1095"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint16_linear_636_3574"
                x1="595"
                y1="700.001"
                x2="595"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint17_linear_636_3574"
                x1="1145"
                y1="700.001"
                x2="1145"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint18_linear_636_3574"
                x1="645"
                y1="700.001"
                x2="645"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint19_linear_636_3574"
                x1="1195"
                y1="700.001"
                x2="1195"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint20_linear_636_3574"
                x1="695"
                y1="700.001"
                x2="695"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint21_linear_636_3574"
                x1="1245"
                y1="700.001"
                x2="1245"
                y2="8.99967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint22_linear_636_3574"
                x1="1279.14"
                y1="-7.50005"
                x2="195.483"
                y2="363.845"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0A890A" />
                <stop offset="0.0001" stopColor="#0A890A" stopOpacity="0" />
                <stop offset="0.425" stopColor="#11EF11" />
                <stop offset="1" stopColor="#0A890A" stopOpacity="0" />
              </linearGradient>
              <radialGradient
                id="paint23_diamond_636_3574"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(918 118) rotate(90) scale(16)"
              >
                <stop stopColor="#11EF11" />
                <stop offset="0.83" />
              </radialGradient>
              <radialGradient
                id="paint24_diamond_636_3574"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(918 118) rotate(90) scale(32)"
              >
                <stop stopColor="#11EF11" />
                <stop offset="0.83" />
              </radialGradient>
            </defs>
          </svg>
          <svg
            width="375"
            height="386"
            viewBox="0 0 375 386"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.graphMobile}
          >
            <g clipPath="url(#clip0_636_4157)">
              <line
                x1="13.1"
                y1="29"
                x2="13.1"
                y2="410"
                stroke="url(#paint0_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="48.1"
                y1="29"
                x2="48.1"
                y2="410"
                stroke="url(#paint1_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="83.1"
                y1="29"
                x2="83.1"
                y2="410"
                stroke="url(#paint2_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="118.1"
                y1="29"
                x2="118.1"
                y2="410"
                stroke="url(#paint3_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="153.1"
                y1="29"
                x2="153.1"
                y2="410"
                stroke="url(#paint4_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="188.1"
                y1="29"
                x2="188.1"
                y2="410"
                stroke="url(#paint5_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="223.1"
                y1="29"
                x2="223.1"
                y2="410"
                stroke="url(#paint6_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="258.1"
                y1="29"
                x2="258.1"
                y2="410"
                stroke="url(#paint7_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="293.1"
                y1="29"
                x2="293.1"
                y2="410"
                stroke="url(#paint8_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="328.1"
                y1="29"
                x2="328.1"
                y2="410"
                stroke="url(#paint9_linear_636_4157)"
                strokeWidth="0.2"
              />
              <line
                x1="363.1"
                y1="29"
                x2="363.1"
                y2="410"
                stroke="url(#paint10_linear_636_4157)"
                strokeWidth="0.2"
              />
              <path
                d="M34 201.621C34.4359 201.044 35.7786 200.238 37.6617 201.621C39.5449 203.003 50.4778 219.809 55.7088 228.039C59.8936 234.705 71.245 245.717 83.1717 236.433C98.0802 224.829 102.527 206.312 115.866 206.805C129.205 207.299 128.159 214.459 139.928 213.472C149.344 212.682 156.058 207.875 158.237 205.571C161.724 201.703 169.204 192.489 171.315 186.313C175.059 175.356 182.823 165.327 197.208 172.24C207.971 177.412 215.435 184.714 219.702 178.412C225.868 169.304 233.303 146.069 241.672 142.118C250.042 138.168 252.659 142.612 255.798 144.587C258.937 146.562 263.381 147.056 275.412 138.662C287.444 130.267 324.323 110.515 334 111.009"
                stroke="url(#paint11_linear_636_4157)"
                strokeOpacity="0.8"
                strokeWidth="2"
              />
              <g style={{ mixBlendMode: 'color-dodge' }}>
                <circle
                  cx="244.5"
                  cy="140.5"
                  r="4.5"
                  fill="url(#paint12_diamond_636_4157)"
                />
              </g>
              <g style={{ mixBlendMode: 'color-dodge' }}>
                <circle
                  cx="245"
                  cy="141"
                  r="9"
                  fill="url(#paint13_diamond_636_4157)"
                  fillOpacity="0.5"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M220.2 100C218.433 100 217 101.433 217 103.2V117.8C217 119.567 218.433 121 220.2 121H236.038C236.451 121 236.839 121.199 237.079 121.536L243.189 130.081C243.702 130.799 244.769 130.795 245.277 130.074L251.285 121.543C251.524 121.203 251.915 121 252.331 121H266.8C268.567 121 270 119.567 270 117.8V103.2C270 101.433 268.567 100 266.8 100H220.2Z"
                fill="white"
                fillOpacity="0.1"
              />
              <text x={220} y={115} style={{ fill: 'white' }}>
                {currentPrice.toFixed(4)}
              </text>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_636_4157"
                x1="13"
                y1="410"
                x2="13"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_636_4157"
                x1="48"
                y1="410"
                x2="48"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_636_4157"
                x1="83"
                y1="410"
                x2="83"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_636_4157"
                x1="118"
                y1="410"
                x2="118"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_636_4157"
                x1="153"
                y1="410"
                x2="153"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_636_4157"
                x1="188"
                y1="410"
                x2="188"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_636_4157"
                x1="223"
                y1="410"
                x2="223"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_636_4157"
                x1="258"
                y1="410"
                x2="258"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_636_4157"
                x1="293"
                y1="410"
                x2="293"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_636_4157"
                x1="328"
                y1="410"
                x2="328"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_636_4157"
                x1="363"
                y1="410"
                x2="363"
                y2="28.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#999999" stopOpacity="0" />
                <stop offset="0.545" stopColor="white" />
                <stop offset="1" stopColor="#999999" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_636_4157"
                x1="348.969"
                y1="104.809"
                x2="37.6743"
                y2="211.162"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0A890A" />
                <stop offset="0.0001" stopColor="#0A890A" stopOpacity="0" />
                <stop offset="0.425" stopColor="#11EF11" />
                <stop offset="1" stopColor="#0A890A" stopOpacity="0" />
              </linearGradient>
              <radialGradient
                id="paint12_diamond_636_4157"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(244.5 140.5) rotate(90) scale(4.5)"
              >
                <stop stopColor="#11EF11" />
                <stop offset="0.83" />
              </radialGradient>
              <radialGradient
                id="paint13_diamond_636_4157"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(245 141) rotate(90) scale(9)"
              >
                <stop stopColor="#11EF11" />
                <stop offset="0.83" />
              </radialGradient>
              <clipPath id="clip0_636_4157">
                <rect width="375" height="386" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={styles.numbers} ref={numbersRef}>
          <div className={styles.numberItem}>
            <span className={styles.numberValue}>{landingInfo?.users}</span>
            <span className={styles.numberLabel}>Всего пользователей</span>
          </div>
          <div className={styles.numberItem}>
            <span className={styles.numberValue}>{landingInfo?.amount}</span>
            <span className={styles.numberLabel}>Всего инвестировано</span>
          </div>
          <div className={styles.numberItem}>
            <span className={styles.numberValue}>{landingInfo?.percent}</span>
            <span className={styles.numberLabel}>Рост токена в %</span>
          </div>
        </div>
      </div>
    </div>
  );
};
