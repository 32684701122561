import { FC } from 'react';
import cn from 'classnames';
import styles from './projects.module.scss';
import { PdfIcon } from '../../icons';
import { Popup } from '../../../ui-kit';

export const PopupBugsContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapBug)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>Баги и мотоциклы</span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />I кв
          2022 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Производство и модернизация малой мототехники (баги, квадроциклы,
            мотоциклы)
          </p>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Для ценителей активного отдыха компания «Lime» производит
            высококлассную технику:
            <br />
            Багги , квадроциклы , трайки , велосипеды, самокаты.
          </p>
          <p className={styles.popupText}>
            Техника компании отличается гарантированной надежностью и повышенной
            проходимостью, что позволит использовать ее как в целях активного
            отдыха, так и для выполнения специфических задач.
          </p>
        </div>
        <img
          className={styles.popupImageBugs}
          src="/images/main/projects/motorcycle.webp"
        />
      </div>
      <div className={styles.popupMiddleCard}>
        Заботясь об окружающей среде и состоянии экосистемы, команда
        разработчиков переводит всю технику{' '}
        <span className={styles.textAccent}>
          на&nbsp;электро&nbsp;обеспечение.{' '}
        </span>
      </div>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Благодаря разработке генератора собственного производства
          увеличивается ход транспорта в 3 раза от имеющихся на рынке аналогов,
          т&nbsp;е. владелец до&nbsp;следующей подзарядки проедет путь в три
          раза больше.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Время полной зарядки займет всего 30 мин.
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Баги и мотоциклы
      </a>
    </div>
  );
};

export const PopupShampooContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство премиальной продукции Din Premium
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2022 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            DIN PREMIUM GROUP - будущее в производстве натуральных продуктов и забота о вашем здоровье!
          </p>
          <p className={styles.popupText}>
            DIN PREMIUM, Министерство сельского хозяйства России и Институт экологического здоровья
            объединили свои ресурсы для разработки и производства высокоэффективной натуральной продукции,
            обеспечивающей комплексное оздоравливающее воздействие на организм человека.
          </p>
          <p className={styles.popupText}>
            Продукция с улучшенным составом представляет собой идеальное сочетание витаминов и минералов.
            Технологические особенности могут быть изменены с учетом индивидуальных потребностей каждого организма.
            Компания занимается производством и поставкой 100% органической продукции.
          </p>
        </div>
        <img
          className={styles.popupImageShampoo}
          src="/images/main/projects/shampoo.png"
        />
      </div>
      <h3 className={styles.popupListTitle}>DIN PREMIUM является:</h3>
      <ul className={styles.popupList}>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Крупнейшим производителем сыродавленных масел в России, а также капсул на их основе.
        </li>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Единственным поставщиком йеменского дикого мёда SIDR в России.
        </li>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Производителем уникального органического питания для спортсменов.
        </li>
      </ul>
      <a
        className={styles.popupLargeLink}
        href="https://dinpremium.com/"
        target="_blank"
      >
        https://dinpremium.com/
      </a>
    </div>
  );
};

export const PopupPhoneContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>Маркетплейс Dada Bazar</span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Мессенджер с широким функционалом
          </p>
          <p className={styles.popupText}>
            <span className={styles.popupTextBold}>Dadabazar</span> — это
            оптовый маркетплейс с товарами из Турции и Китая для розничной и
            оптовой торговли, где вы можете заказывать и продавать товары в
            несколько кликов.
          </p>
          <p className={styles.popupText}>
            Продавцы могут выставлять свои товары на площадке Dadabazar или
            заказывать оптом у надежных поставщиков для дальнейшей перепродажи в
            маркетплейсах, интернет-магазинах и розничных офлайн-магазинах.
          </p>
          <p className={styles.popupText}>
            Предприниматели могут легко найти поставщиков для оснащения своего
            бизнеса по выгодным ценам.
          </p>
          <p className={styles.popupText}>
            Также на площадке есть возможность заказывать товары в розницу, на
            выбор представлены десятки тысяч товаров самых разных категорий,
            включая одежду, товары для дома и электронику.
          </p>
          <p className={styles.popupText}>
            Мы работаем в товарном бизнесе с 2008 года. За это время мы
            совершили более 10.000 грузоперевозок. Мы основали площадку, на
            которой продавцы могут приобретать товары для своего бизнеса
            напрямую от производителей Турции и Китая, и продавать их своим
            клиентам.
          </p>
        </div>
        <img
          className={styles.popupImagePhone}
          src="/images/main/projects/phone.webp"
        />
      </div>
      <a
        className={styles.popupLargeLink}
        href="https://dadabazar.online/"
        target="_blank"
      >
        https://dadabazar.online/
      </a>
    </div>
  );
};

export const PopupStabilizeContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapStabilize)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство инверторных стабилизаторов
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          IV кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Компания «Лайм» представляет инверторный стабилизатор нового поколения,
            который обеспечивает надежное и стабильное напряжение в диапазоне от 20 до 400 Вольт
            и полностью устраняет реактивные составляющие в сети.
          </p>
        </div>
        <img
          className={styles.popupImageStabilize}
          src="/images/main/projects/stabilize.webp"
        />
      </div>
      <p className={styles.popupText}>
        В отличие от других производителей, наш стабилизатор оснащен функциями выбора: источник питания UPS, солнечные панели и альтернативные источники энергии.
      </p>
      <p>
        Он способен работать как в однофазных, так и в трехфазных сетях, обеспечивая автоматическую синхронизацию и поддержку нулевого провода. Все это реализовано без использования громоздких трансформаторов, что делает устройство компактным и бесшумным. С выходной мощностью от 1 до 60 кВт, стабилизатор от «Лайм» является идеальным решением для обеспечения стабильного электроснабжения в любых условиях.
      </p>
      <h3 className={styles.popupListTitle}>Преимущества:</h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Стабилизатор надежно поддерживает напряжение от 20 В;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Дополнительные функции: ИБП и возможность подключения альтернативных источников энергии;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Экономия электроэнергии на 20-40% благодаря фильтрации напряжения и исключению реактивных составляющих;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Низкая себестоимость производства по сравнению с конкурентами.
        </li>
      </ul>
      <p className={styles.popupText}>
        Область применения:
      </p>
      <p>Крупные предприятия с высоким потреблением электроэнергии;</p>
      <p>Системы на основе возобновляемых источников энергии;</p>
      <p>Организации, использующие оборудование, требующее стабильного напряжения;</p>
      <p>Операторы сотовой связи;</p>
      <p>Медицинские учреждения и другие организации;</p>
      <p>Жилые дома, страдающие от низкого напряжения; </p>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Инверторный стабилизатор
      </a>
    </div>
  );
};

export const PowerbankPopup: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство автономных пауэрбанков
        </span>
        <span className={styles.popupTitleDate}>
          <img
            src="/images/main/spark_white.png"
            className={styles.popupSpark}
          />
          II кв 2025 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Всегда быть на связи — базовый запрос современного делового человека.
          </p>
          <p className={styles.popupText}>
            Электронно-механический powerbank — будущее, которое уже наступило.
            Этот компактный помощник сам заряжается и способен подзаряжать ваше устройство вне зависимости от того,
            где вы находитесь и есть ли доступ к электричеству.
          </p>
          <p
            className={cn(
              styles.popupText,
              styles.popupTextBold,
              styles.popupTextPoint
            )}
          >
            <img src="/images/main/spark.png" className={styles.popupSpark} />
            Он разработан для автономной работы и зарядки.
          </p>
        </div>
        <img
          className={styles.popupImagePowerbank}
          src="/images/main/projects/powerbank.webp"
        />
      </div>
      <p className={styles.popupText}>
        Прелесть устройства заключается в том, что оно будет оснащено современными электронными технологиями,
        включая встроенный фонарь, радиоприёмник, mp3 и независимое зарядное устройство
        для обеспечения его функционирования и заряда АКБ.
        Кроме того, его эргономика и дизайн будут компактными и футуристичными, а также привлекательными и удобными для пользователя.
      </p>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Аннотация зарядного устройства
      </a>
    </div>
  );
};

export const PopupParaffinContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство медицинского парафина
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Производство парафина из нефти по уникальной технологии.
          </p>
          <p className={styles.popupText}>
            Команда Lime разработала уникальный технологический процесс, позволяющий извлекать высококачественный парафин из нефти.
          </p>
          <p className={styles.popupText}>
            Россия (включая Прикаспийский нефтегазоносный бассейн), Казахстан, США располагают огромными запасами парафиновой нефти.
          </p>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Из одной тонны сырья мы получаем до 40% чистого парафина.
          </p>
        </div>
        <img
          className={styles.popupImageParaffin}
          src="/images/main/projects/paraffin.png"
        />
      </div>
      <h3 className={styles.popupListTitle}>
        Проект по производству парафина:
      </h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Даст значительное развитие нефтехимической отрасли в республике.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Повысит престиж региона и страны, создав новые возможности.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Сформирует базы для подготовки высококвалифицированных технических специалистов.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Позволит разработать инновационные и устойчивые продукты в сфере нефтехимии и переработки отходов нефтегазовой отрасли.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Увеличит поступления в государственный бюджет за счет налоговых отчислений компаний и мультипликативного эффекта от инвестиций, а также обеспечит рост заработной платы сотрудников.
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Производство парафина
      </a>
    </div>
  );
};

export const PoputHouseContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapHouses)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство автономных домов&nbsp;фахверк
        </span>
        <span className={styles.popupTitleDate}>
          <img
            src="/images/main/spark_white.png"
            className={styles.popupSpark}
          />
          IV кв 2025 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Благодаря нашим уникальным разработкам стали возможны автономные
            дома без центральных сетей, электромобили и катера без подзарядки и
            ограничений в движении!
          </p>
          <p className={styles.popupText}>
            Все это — составляющие новой экологической модели жизни на основе отказа от избыточного потребления.
          </p>
          <p className={styles.popupText}>
            Речь идет об автономном экопоселке, который расположится на живописном природном ландшафте площадью 300 гектаров на берегу большого водоема. Экопоселок рассчитан на 1000 домов. Он будет обеспечен полной социальной инфраструктурой, последовательностью и безопасностью.
          </p>
        </div>
        <img
          src="/images/main/projects/houses.webp"
          className={styles.popupHouseImage}
        />
      </div>
      <h3 className={styles.popupListTitle}>
        В инфраструктуру эко-поселка будут входить:
      </h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          детсад и школа
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          многофункциональная больница
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          многофункциональный спортивный комплекс
          <br />
          (открытые и закрытые бассейны, тренажерные залы, теннисные корты,
          футбольное поле, баскетбольная и волейбольная площадки, картинг и др.)
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          торгово-развлекательный центр
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          магазины и рестораны 
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          фермерское хозяйство с теплицами 
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          мусороперерабатывающий комплекс
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          служба безопасности
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
           яхт-клуб
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          вертолетная площадка
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Дома фахверк
      </a>
    </div>
  );
};

interface Props {
  currentSlide: number;
  isOpen: boolean;
  onClose: () => void;
}

export const PropjectsPopups: FC<Props> = ({
  currentSlide,
  isOpen,
  onClose,
}) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <>
        {(currentSlide === 0 && <PopupBugsContent />) || null}
        {(currentSlide === 1 && <PopupShampooContent />) || null}
        {(currentSlide === 2 && <PopupPhoneContent />) || null}
        {(currentSlide === 3 && <PopupParaffinContent />) || null}
        {(currentSlide === 4 && <PopupStabilizeContent />) || null}
        {(currentSlide === 5 && <PowerbankPopup />) || null}
        {(currentSlide === 6 && <PoputHouseContent />) || null}
      </>
    </Popup>
  );
};
