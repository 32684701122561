import { FC } from 'react';
import cn from 'classnames';
import styles from './footer.module.scss';

export const Footer: FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.wrap}>
      <div className={styles.inner}>
        <div className={styles.footerTop}>
          <div className={styles.footerItem}>
            <h3 className={styles.itemTitle}>Навигация</h3>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <a className={styles.listLink} href="/#">
                  Главная
                </a>
              </li>
              <li className={styles.listItem}>
                <a className={styles.listLink} href="/#about">
                  О нас
                </a>
              </li>
              <li className={styles.listItem}>
                <a className={styles.listLink} href="/#projects">
                  Проекты
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://blog.lime.fun/"
                  className={styles.listLink}
                  target="_blank"
                >
                  Блог
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://lime-fun.gitbook.io/white-paper"
                  className={styles.listLink}
                  target="_blank"
                >
                  White Paper
                </a>
              </li>
              {/* <li className={styles.listItem}>
                <a
                  className={cn(styles.listLink, styles.listLinkDesktop)}
                  href="/#"
                >
                  Политика конфиденциальности
                </a>
              </li> */}
            </ul>
          </div>
          <div className={styles.footerItem}>
            <h3 className={styles.itemTitle}>Свяжитесь с нами</h3>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <a className={styles.listLink} href="mailto:info@lime.fun">
                  info@lime.fun
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footerItem}>
            <h3 className={styles.itemTitle}>Мы в соцсетях</h3>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <a className={styles.listLink} href="https://t.me/limetechno">
                  Telegram канал
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  className={styles.listLink}
                  href="https://www.instagram.com/lime_fun"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <a
          className={cn(styles.listLink, styles.listLinkMobile)}
          href="https://limevers.gitbook.io/ime-technologies/10.-disclaimer"
          target="_blank"
        >
          Disclaimer
        </a>
        <div className={styles.footerBottom}>
          <p className={styles.copyright}>
            © {currentYear} Lime. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
